body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 20px;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  width: 80px;
}

.navbar {
  height: 75px;
}

.navbar-custom {
  background-color: #fa9eac;
  padding-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adjust the values as needed */
  z-index: 1000; /* Ensure the navbar is above other content */
  padding-bottom: 10px;
}

.nav-link {
  display: inline-block;
  font-size: 30px;
  /* font-size: 3rem; */
  color: azure;
  text-decoration: none;
  transition: transform 0.3s ease, font-size 0.3s ease;
  height: 30px;
  line-height: 30px;
}
.nav-link:hover {
  font-size: 30px;
  color: azure;
}

.absolute-title {
  position: absolute;
  top: 150px;
  right: 70px;
}

.absolute-alert1 {
  position: absolute;
  top: 345px;
  right: 372px;
  -webkit-text-stroke: 0.5px black;
  font-size: 30px;
}
.absolute-separator {
  position: absolute;
  top: 385px;
  right: 310px;
  width: 310px;
  border-top: 5px solid black;
  z-index: 10;
}
.absolute-alert2 {
  position: absolute;
  top: 400px;
  right: 310px;
}
.btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: black;
  color: black;
}
.absolute-btn {
  position: absolute;
  top: 460px;
  height: 20px;
  right: 510px;
}
.absolute-btn2 {
  position: absolute;
  height: 20px;
  top: 460px;
  right: 390px;
}
.absolute-btn3 {
  position: absolute;
  height: 20px;
  top: 460px;
  right: 260px;
}
.alert {
  z-index: 2;
}
.card-alert {
  position: absolute;
  z-index: 1;
  top: 350px;
  right: 240px;
  width: 425px;
  height: 170px;
  /* background-image: url("./card-bg.jpg"); */
}
.card-name {
  background-image: url("./assets/card-bg-paper.jpg");
  border: 2px solid #000; /* Adjust the border color and width as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust the shadow properties as needed */
  border-radius: 8px; /* Optional: adds rounded corners */
  padding: 16px; /* Optional: adds padding inside the div */
  max-width: 500px; /* Adjust the width as needed */
  margin: 0 auto;
}

.modal-open .container-fluid, .modal-open  .container .body {
  -webkit-filter: blur(10px) grayscale(90%);
}
.modal-content{
  height: 700px;
}
.dropdown-menu{
  background-color: #fa9eac;
  border-radius: 0;
}
.dropdown-item{
  color: black;
  font-weight: 60px;
}
.dropdown-divider{
  border-top: 1px solid black;
}
.button-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.button-container-btn {
  margin: 20px;
  text-align: center;
}
.rsvp-welcome {
  text-align: center;
}

.gmap_canvas {
  border-radius: 15px; /* Adjust this value for more or less rounding */
  overflow: hidden; /* Ensures the iframe content follows the rounded corners */
  width: 250px; /* Ensure this matches the iframe width */
  height: 250px; /* Ensure this matches the iframe height */
  margin-left: 20px;
  border-color: black;
}

#gmap_canvas {
  border-radius: 15px; /* Apply the same rounding to the iframe itself */
  width: 100%; /* Make sure the iframe fits its container */
  height: 100%; /* Make sure the iframe fits its container */
  border: 2px; /* Remove any default border */
  border-color: black;
}
.rsvp-cards-container {
  display: flex;
}

.navbar-nav .nav-item .nav-link {
  display: flex;
  align-items: center;
}
.rsvp_modal {
  backdrop-filter: blur(8px);
  z-index: 1000;

}
.rsvp_modal .modal-body {
  max-height: 60vh; /* Adjust the height as needed */
  overflow-y: auto;
}

.rsvp-image {
  height: 60vh;
  width: auto;
  max-width: 100%;
}

@media (max-width: 576px) {
  .rsvp-image {
    height: auto;
    width: 100%;
  }

  .mapouter iframe {
    width: 100%;
  }
}

.event-container {
  font-family: "Roboto", sans-serif;
  max-width: 800px;
  margin: 0 auto;
}

.event-title {
  padding-top: 20px;
}

.event-container h3.year {
  font-size: 40px;
  text-align: center;
  border-bottom: 1px solid #b1b1b1;
}

.event-container .event {
  box-shadow: 0 4px 16px -8px rgba(0, 0, 0, 0.4);
  display: flex;
  border-radius: 8px;
  margin: 0px 0;
}

.event {
  background: #211611;
  max-width: 800px;
}

.event .event-left {
  background: #4A3327;
  min-width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eee;
  padding: 20px 48px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px 0 0 8px;
}

.event .event-left .date {
  font-size: 56px;
}

.event .event-left .month {
  font-size: 16px;
  font-weight: normal;
}

.event .event-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
}

.event .event-right h3.event-title {
  font-size: 24px;
  margin: 0px 0 10px 0;
  color: #218bbb;
  text-transform: uppercase;
}

.event .event-right .event-timing {
  background: #fff8ba;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  padding: 8px;
  border-radius: 16px;
  margin: 10px 0;
  font-size: 14px;
}

.event .event-right .event-timing img {
  height: 20px;
  padding-right: 2px;
}

@media (max-width: 700px) {
  .event {
    flex-direction: column;
  }

  .event .event-left {
    padding: 0;
    border-radius: 8px 8px 0 0;
  }

  .event .event-left .event-date .date,
  .event .event-left .event-date .month {
    display: inline-block;
    font-size: 24px;
  }

  .event .event-left .event-date {
    padding: 5px 0;
  }
}

.card .custom-card {
  background-color: #B09369 !important;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 0 0 8px 8px;
}

.event-card {
  padding-bottom: 15px;
}

.gmap_canvas_modal {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}
.gmap_iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}



.btn-circle {
  width: 1vw;
  height: 1vh;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circle.btn-lg {
  width: 1vw;
  height: 1vh;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circle.btn-xl {
  width: 1vw;
  height: 1vh;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}


/* use negative margins to accommodate wider button text */

.btn-circle span {
  margin: 0 -2rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure the container takes the full height of the viewport */
}

.card-container .card {
  width: 20rem;
  height: 38rem; /* Fixed height for the card */
  margin: 10px;
  display: flex;
  flex-direction: column;
  border: 4px solid #ebc07f; /* Blue border */
  box-shadow: 10px;
  border-radius: 10px; /* Optional: rounded corners */
}

.card-container .card img {
  height: 18rem; /* Fixed height for the image */
  object-fit: cover;
}

.card-container .card-body {
  flex: 1; /* Make the body take the remaining space */
  display: flex;
  flex-direction: column;
}

.card-container .card-body .card-title {
  flex-shrink: 0;
}

.card-container .card-body .card-text {
  flex-grow: 1; /* Make the text take the remaining space in the body */
}

.card-container .card-body .btn {
  flex-shrink: 0; /* Prevent the button from growing */
}
.bio-card {
  background-color: #4A3327;
  overflow-y: auto;
  position: absolute;
}
.bio-text{
  font-size: 13px;
}



.body {
	font-family: "Poppin", sans-serif;
	background-color: white;
	height: 100vh;
	display: flex;
  width: 100vw;
	/* align-items: center;
	justify-content: center; */
}
.book {
  top: 2vh;
	width: 41vw;
	height: 40.6vw;
  margin-left: 35%;
	position: relative;
	transition-duration: 1s;
	perspective: 1500;
}
input {
	display: none;
}
.cover, .back-cover {
	background-color: #4A3327;
	width: 100%;
	height: 100%;
	border-radius: 0 15px 15px 0;
	box-shadow: 0 0 10px rgb(41, 41, 41);
	display: flex;
	align-items: center;
	justify-content: center;
	transform-origin: center left;
}
.cover {
	position: absolute;
	z-index: 6;
	transition: transform 1s;
}
.cover img {
  transition: transform 1.5s;
  border-radius:0 15px 15px 0;
  height: 40.6vw;
  width: 100%;
  /* height: 38vw;
  width: 37vw;
  border-radius: 1vw 3vw 1vw 3vw;
  margin-top: 1.3vw;
  margin-left: 0.3vw;
  border: 0.3vw solid #ddd;
  cursor: pointer; */
}
.cover label {
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.back-cover {
	position: relative;
	z-index: -1;
}
.page {
  width: 40vw;
	height: 65vh;
	border-radius: 0 1vw 1vw 0;
	transform-origin: left;
	transform-style: preserve-3d;
	transform: rotateY(0deg);
	transition-duration: 1.5s;
}

.back-page img {
  transition-duration: 1.5s;
  border-radius:2vw 0 0 2vw;
  /* width: 33vw; */
  height: 40.6vw;
}

.front-page img {
  transition-duration: 1.5s;
  border-radius:0 2vw 2vw 0;
  /* width: 33vw; */
  height: 40.6vw;
  margin-left: -30px;
}

.front-page {
	position: absolute;
  background-color:transparent;
	width: 4vw;
	height: 67vh;
	backface-visibility: hidden;
  z-index: 99;
}
.back-page {
  position: absolute;
	transform: rotateY(180deg);
  background-color:transparent;
	width: 37.2vw;
	height: 67vh;
	backface-visibility: hidden;
	z-index: 99;
  left: 3.4vw;
}
.next, .prev {
	position: absolute;
	top: 1em;
	cursor: pointer;
}
.next {
	right: 2em;
}
.prev {
	left: 1em;
}
#page1 {
	z-index: 4;
}
#page2 {
	z-index: 3;
}
#page3 {
	z-index: 2;
}
#page4 {
	z-index: 1;
}
#checkbox-cover:checked ~ .book {
	transform: translateX(11vw);
}
#checkbox-cover:checked ~ .cover img {
	z-index: 0;
}
#checkbox-cover:checked ~ .book .cover {
	transition: transform 1.5s, z-index 0.5s 0.5s;
	transform: rotateY(-180deg);
	z-index: 1;
}
#checkbox-cover:checked ~ .book .page {
	box-shadow: 0 0 0 rgb(99, 98, 98);
}
#checkbox-page1:checked ~ .book #page1 {
	transform: rotateY(-180deg);
	z-index: 2;
}
#checkbox-page2:checked ~ .book #page2 {
	transform: rotateY(-180deg);
	z-index: 3;
}
#checkbox-page3:checked ~ .book #page3 {
	transform: rotateY(-180deg);
	z-index: 4;
}